.container {
	position: absolute;
	top: 0;
	width: 100vw;
}
.results li {
	border-bottom: 1px solid var(--color-manatee);
	border-right: 0;
	border-left: 0;
	border-top: 0;
	overflow: hidden;
}
.results li p {
	margin-left: 32px;
}
.results li {
	height: 50px;
	list-style: none;
}
.loading {
	height: 40vh;
}
.loading img {
	width: 60px;
}
.message {
	text-align: center;
}
.message img {
	margin-top: 81px;
	margin-bottom: 32px;
	width: 56px;
}
.message p:first-child {
	margin-top: 43px;
}
.message p {
	font-size: 16px;
}
