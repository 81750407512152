.inputFile {
	background-color: var(--color-white);
	border: 1px solid var(--color-indigo);
	color: var(--color-indigo);
	padding: 16px;
	border-radius: 3px;
	font-weight: bold;
	display: block;
	margin: 16px;
	font-size: 16px;
}
.inputFile:after {
	content: '+';
	position: absolute;
	right: 14px;
	padding-right: 16px;
}
.inputFile:hover {
	background-color: var(--color-indigo);
	border: none;
	color: var(--color-white);
}
