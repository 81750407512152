.title {
	color: var(--color-indigo);
	margin: auto;
	text-align: center;
	font-size: 14px;
}
.image {
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
}
