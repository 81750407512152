html {
	min-height: 100vh;
	/*Primary*/
	--color-indigo: #3743aa;
	--color-white: #ffffff;
	/*Secondary*/

	/*Grays*/
	--color-manatee: #edf0f4;
	--color-neutral: #969ba0;
	--color-charcoal: #3e3e42;

	background-color: #fafafa;
}
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
.center {
	display: flex;
	justify-content: center;
	align-items: center;
}
