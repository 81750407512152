.container {
	background-color: white;
	border-bottom: 2px solid var(--color-neutral);
	align-items: center;
}
.container i {
	font-size: 15px;
	margin-left: 8px;
}
.container p {
	font-size: 16px;
	font-weight: bold;
	padding-bottom: 0;
}
.title {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
}
.title h3 {
	padding: 0;
}

.titleGoBack {
	display: inline-block;
}
