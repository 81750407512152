.container {
	position: relative;
	display: inline-block;
	width: 50%;
	overflow: hidden;
	height: 100px;
}

.container div {
	position: absolute;
	bottom: 0;
	left: 0;
	background: #0000008c;
	color: white;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	padding: 0 16px;
}
.thumbnail {
	top: 50%;
	width: 100%;
	position: relative;
	transform: translateY(-50%);
}
